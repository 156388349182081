import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {NgbDate, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {CustomDateParserFormatter} from '../../util/custom-date-parser-formatter';
import {AlertService} from '../../../core/services/alert.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
  ],
})
export class DatepickerComponent implements OnInit, OnChanges {
  public date: NgbDateStruct;
  @Input()
  placeholder: string;
  @Input()
  hasError: boolean;
  @Input()
  submitted: boolean;
  @Input()
  datepickerStyle: string;
  @Input()
  readonly: boolean = false;
  @Output()
  selectedDate = new EventEmitter<any>();

  @ViewChild('myInput', {read: ElementRef}) myInput: ElementRef<HTMLInputElement>;

  constructor(private alertService: AlertService, private translate: TranslateService) {
  }

  setDateFromDateString(date: string): void {
    var parsedDate = new Date(Date.parse(date));
    this.date = new NgbDate(parsedDate.getFullYear(), parsedDate.getMonth() + 1, parsedDate.getDate());
  }

  setDateSelect(date: NgbDate): void {
    var dateToReturn = new Date(date.year, date.month - 1, date.day + 1);
    this.selectedDate.emit(dateToReturn);
  }

  ngOnInit(): void {
    if (this.placeholder) {
      this.setDateFromDateString(this.placeholder);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.placeholder) {
      this.setDateFromDateString(this.placeholder);
    }
  }

  onDateSelect(): Date {
    let dateToReturn = null;
    if (this.date) {
      dateToReturn = new Date(
        Date.UTC(this.date.year, this.date.month - 1, this.date.day, 0, 0)
      );
    }
    return dateToReturn;
  }

  onDateSelectFinal(): void {
    let dateToReturn = this.onDateSelect();
    if (isNaN(dateToReturn.getTime())) {
      this.translate.get('INVALID_DATE').subscribe(invalidDateTranslate =>
        this.alertService.error(invalidDateTranslate, {closeButton: true, autoClose: false})
      );
    } else {
      this.selectedDate.emit(dateToReturn);
    }
  }

}
