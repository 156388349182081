<form class="form-inline" tabindex="-1">
  <div class="form-group {{datepickerStyle}}">
    <div class="input-group {{datepickerStyle}}">
      <input #d="ngbDatepicker" (dateSelect)="onDateSelectFinal()" (focusout)="onDateSelectFinal()"
             (keyup)="onDateSelect()"
             (keyup.enter)="onDateSelectFinal()"
             [(ngModel)]="date"
             class="{{hasError? submitted? 'has-error is-invalid': 'invisible-error': ''}} custom-form-component form-control"
             name="dp" ngbDatepicker
             #myInput
             placeholder="dd.mm.yyyy." tabindex="0"
             [readOnly]="readonly"
      >
      <div class="input-group-append">
        <button (click)="d.toggle()"
                class="btn btn-outline-secondary fa fa-calendar {{hasError && submitted? 'has-error': ''}}"
                type="button"><i
        ></i></button>
      </div>
    </div>
  </div>
</form>
